import React, { Component } from "react";
import ContentWrapper from "../../../Layout/ContentWrapper";
import Moment from "moment";
import axios from "../../../../services/axios";
import {
    Container, Button, FormGroup,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import classnames from 'classnames';
import { Document, Page } from 'react-pdf';
import { API_URL } from "../../../../services/constants";
import KeywordConfig from "../../../../services/KeywordConfigService";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DeleteAlert, SuccessAlert } from "../../../Common/AppAlerts";
import KeywordConfigService from "../../../../services/KeywordConfigService";
const MySwal = withReactContent(Swal);

class CustomerDetails extends Component {
    state = {
        activeTab: '1',
        customerId: 0,
        customer: {},
        isApproved: 0,
        usersList: [],
        tarrifsList: [],

        smscList:[],
        smscId:0,
        showSmscDetails:false,
        smscType:-1,
        paymentType: "",

        tariffId: 22,
        monthlySmsLimit: 0,

        customerModal: false,
        editedCustomer: {
            tariffFk: 0,
            monthlyLimit: 0,
            autoRenewal: ""
        },
        imgURl:"",
        img:{},
        previewURl:{},
        attachmentUrl:"",
        showInterfaceField: false,
        isActiveAction: false,
        userTypeList: [],
        selectedUserId: 0,

        senderModal: false,

        approval_type: "PARALLEL",
        customerType: "",
        smsBalance: 0,
        shortcodeModal: false,
        balance: 0,

        serviceList: [],
        serverList: [],
        shortcodeList: [],
        sharedShortcodeList: [],
        keywordList: [],
        //customerType: [],
        addMoShortcodeForm: {
            code: null,
            type: null,
            url: null,
            clientName: null,
            httpMethod: null,
            params: null,
            sendingInterface: null,
            status: "ACTIVE",
            inactiveResponse: null
        },

        editMoShortcode: {
            id: 0,
            code: null,
            type: null,
            url: null,
            clientName: null,
            httpMethod: null,
            params: null,
            sendingInterface: null,
            status: "ACTIVE",
            inactiveResponse: null
        },
        addNewKeywordForm: {
            shortcode: 0,
            keyword: "",
            url: "",
            clientName: "",
            params: "",
            httpMethod: "",
            inactiveResponse: "",
            status: "ACTIVE"
        },

        editKeywordForm: {
            id: 0,
            shortCode: "",
            keyword: "",
            url: "",
            clientName: "",
            params: "",
            httpMethod: "",
            inactiveResponse: "",
            status: "ACTIVE",
        },
        loader: false,
        modeShortcode: true,
        modeKeyword: true,
        keywordModal: false,
        rejectReason: "",
        rejectModal: false,
        level: 0,
        modeSender: false,
        roleModal: false,
        deligateRolesForm: {
            to: "",
            userTypeId: "",
            expirationTime: "",
            isInfinite: "",
            approvalLevel: ""
        },
        senderList: [],
        sender: "",
        monthlyLimitModalVisible: false,
        approvalLevels: [],
        approvers: [],
        hasAttachment: false,
        date: null,
        showDate: false,
        approvedByData: null,
    };

    toggleTab = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }


    ViewCustomerList = () => {
        return this.props.history.push("/admin-customers-list")
    }


    handleClickActiveTab = event => {
        const newActiveTab = event.target.tab;
        this.setState({
            activeTab: newActiveTab,
        })
    }

    componentDidMount() {

        const { state } = this.props.history.location;
        if (state == undefined) {
            return this.props.history.push('/admin-customers-list/')
        }

        this.setState({isApproved: state.isApproved, customerId: state.id, paymentType: state.paymentType});

        this.getSharedMoShortcodeList();
        this.getAllMoShortCodes(state.id);
        this.getAllKeywords(state.id);

        axios.get("/customers/" + state.id)
            .then(res => {
                const customer = res.data;
                this.setState({ customer})
            })
        const attachmentUrl= API_URL+"/img/customer-attachment/"+state.id;
        this.setState({attachmentUrl})

        axios.get("/customers/users-list/" + state.id)
            .then(res => {
                const response = res.data;
                this.setState({ usersList: response })
                console.log(response);
            });
        axios.get("/img/customer-attachment/" + state.id)
            .then(res => {
                const img = res.data;
                console.log(res)
                this.setState({ previewURl:img })

            });

        axios.get("/tariff")
            .then(res => {
                const response = res.data;
                this.setState({ tarrifsList: response })
                console.log(response);
            })

        axios.get("/smsc/dedicated-pending")
            .then(res => {
                const response = res.data;
                this.setState({ smscList: response })
                console.log(response);
            })

    }

    AddActionButtonStyle = {
        color: 'white',
        background: "#003366"
    }

    TableActionButtonStyle = {
        color: 'white',
        background: "#33414e"
    }

    ApproveCustomer(id) {
        console.log(id)
        axios.put("/customers/approve/" + id)
            .then(res => {
                const response = res.data;
                this.ViewCustomerList();
            })

    }

    handleSubmit = event => {
        event.preventDefault();
        this.hideToggelModal();
        const data =
            {
                "customerId": this.state.customerId,
                "tariffId": this.state.tariffId,
                "smscId":this.state.smscId,
                "monthlySmsLimit": this.state.monthlySmsLimit
            }

        console.log(data)

        axios.put("/customers/approve", data).then(res => {
            const response = res.data;
            this.ViewCustomerList();
        })
    }

    getSharedMoShortcodeList = () => {
        KeywordConfig.getSharedMoShortcodes().then(res => {
            this.setState({sharedShortcodeList: res.data});
        });
    };

    AlertDeleteKeyword = (row) =>{
        DeleteAlert().then(willDelete=>{
            if (willDelete) {
                this.DeleteKeyword(row)
            }
        })
    }

    AlertDeleteShortcode = (row) => {
        DeleteAlert().then(willDelete=>{
            if(willDelete){
                this.DeleteShortcode(row)
            }
        })
    }

    DeleteShortcode = (row) => {
        KeywordConfigService.deleteMoShortcode(row.id).then(res=>{
            SuccessAlert("Successfully deleted!", "success")
            this.getAllMoShortCodes(this.state.customerId)
        }).catch(err=>{
            SuccessAlert(err.response.data.message, "info")
        })
    }
    getAllKeywords = (customerId) => {
        KeywordConfig.getKeywordByCustomerId(customerId).then(res => {
            this.setState({keywordList: res.data});
        });
    };

    EditKeyword = (row) => {
        this.setState({modeKeyword: false});

        this.toggleKeywordModal();

        this.getSingleKeyword(row);

    };

    EditShortcodeMode = (row) => {
        this.setState({modeShortcode: false});
        this.setState({
            editMoShortcode: Object.assign(this.state.editMoShortcode, {id: row.id})
        });
        this.getSingleMoShortCode(row);
        this.toggleModalShortcode();

        console.log(row.id);
    };

      getSingleMoShortCode = (row) => {
        KeywordConfig.getMoShortcodeById(row.id).then(res => {
            this.setState({editMoShortcode: res.data});
            console.log(res.data);
        });
    };

    handleCustomerUpdateFomSubmission = event => {

        event.preventDefault();
        this.toggleModalCustomer();


        const data = {
            customerId: this.state.customer.id,
            tariffId: this.state.editedCustomer.tariffFk,
            monthlySmsLimit: this.state.editedCustomer.monthlyLimit,
            autoRenew: this.state.editedCustomer.autoRenewal,

        }
        console.log("data", data)
        axios.put("/customers/auto-renew", data)
            .then(res => {
                const response = res.data;
                this.ViewCustomerList();
            })

    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleChangeDetails = event => {
        this.setState({
            editedCustomer: Object.assign({},
                this.state.editedCustomer, { [event.target.name]: event.target.value })
        })
    }

    handleSmsTypeChange = event => {
        console.log("Am here")
        if ([event.target.value] == "0") {
            this.setState({ showSmscDetails: false, smscId:0, smscType:0})
        } else {
            this.setState({ showSmscDetails: true, smscType:1})

        }
    }

    RejectCustomer(id) {
        axios.put("/customers/reject/" + id)
            .then(res => {
                const response = res.data;
                this.ViewCustomerList();
            })
    }

    EditCustomer() {

        this.setState({
            editedCustomer: Object.assign({},
                this.state.editedCustomer, { tariffFk: this.state.customer.tariffFk })
        })

        console.log(this.state.editedCustomer);
        this.setState({ editedCustomer: { ...this.state.editedCustomer, tariffFk: this.state.customer.tariffFk } })

        this.setState({
            editedCustomer: Object.assign({},
                this.state.editedCustomer, { tariffFk: this.state.customer.tariffFk })
        })

        //     this.setState({editedCustomer:Object.assign({},
        //         this.state.editedCustomer,{[event.target.name]:event.target.value})})


        this.setState({
            editedCustomer: Object.assign({},
                this.state.editedCustomer, { autoRenewal: this.state.customer.autoRenewal })
        })

        this.setState({
            editedCustomer: Object.assign({},
                this.state.editedCustomer, { monthlyLimit: this.state.customer.monthlyLimit })
        })
        console.log(this.state.editedCustomer);
        this.toggleModalCustomer();

    }
    toggleModalCustomer = () => {
        this.setState({
            customerModal: !this.state.customerModal
        })
    }

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    hideToggelModal = () => {
        this.setState({
            modal: false,
        })
    }

    formatDate = (date) => {
        return Moment(date).format('lll')
    }

    previewAttachment=()=>{
        window.open(this.state.attachmentUrl, "_blank")
    }

   handleChangeShortcode = (event) => {
        if (event.target.name === "httpMethod" && event.target.value != "GET") {
            this.setState({showInterfaceField: true});
        }
        if (event.target.value == "GET") {
            this.setState({showInterfaceField: false});
        }
        if (this.state.modeShortcode) {
            this.setState({
                addMoShortcodeForm: Object.assign({}, this.state.addMoShortcodeForm,
                    {[event.target.name]: event.target.value})
            });
        } else {
            this.setState({
                editMoShortcode: Object.assign({}, this.state.editMoShortcode,
                    {[event.target.name]: event.target.value})
            });
        }
    };

    getAllMoShortCodes = (customerId) => {
        KeywordConfig.getMoShortcodeByCustomer(customerId).then(res => {
            const response = res.data;
            this.setState({shortcodeList: response});
        });
    };


    getSingleKeyword = (row) => {
        KeywordConfig.getKeywordById(row.id).then(res => {
            const response = res.data;
            console.log("response: ", response);
            this.setState({
                editKeywordForm: response,
                loader: false
            });
        });
    };

    EditKeyword = (row) => {
        this.setState({modeKeyword: false});

        this.toggleKeywordModal();

        this.getSingleKeyword(row);

    };

    handleSubmitKeyword = event => {
        event.preventDefault();
        this.setState({loader: true});

        if (this.state.modeKeyword) {
            const _data = {
                ...this.state.addNewKeywordForm,
                "customerId": this.state.customerId
            };

            KeywordConfig.createKeyword(_data).then(res => {
                    this.setState({loader: false, keywordModal: false});
                                        this.getAllKeywords(this.state.customerId);
                    this.showSweetAlert("Keyword successfully added!", "success");
                }
            ).catch(err => {
                this.showSweetAlert(err.response.data.message, "info");
            });

        } else {
            let {clientName, httpMethod, id, inactiveResponse, isDeleted, keyword, shortCode, status, url, params} = this.state.editKeywordForm;
            let myData = {
                clientName: clientName,
                httpMethod: httpMethod,
                id: id,
                inactiveResponse: inactiveResponse,
                isDeleted: isDeleted,
                keyword: keyword,
                params: params,
                shortcode: shortCode,
                status: status,
                url: url,
                customerId: this.state.customerId
            }
            /*const _data = {
                ...this.state.editKeywordForm,
            };*/
            KeywordConfig.updateKeyword(myData).then(res => {
                    this.setState({loader: false, keywordModal: false});
                    this.showSweetAlert("Keyword's changes saved successfully!");
                    this.getAllKeywords(this.state.customerId);
                }
            ).catch(err => {
                this.showSweetAlert(err.response.data.message);
            });
        }
    };

    DeleteKeyword = (row) => {
        KeywordConfig.deleteKeyword(row.id).then(res => {
            const response = res.data;
            this.getAllKeywords(this.state.customerId);
        });
    };

    AlertDeleteKeyword = row => {
        DeleteAlert().then((willDelete) => {
            if (willDelete) {
                this.DeleteKeyword(row);
                SuccessAlert(row.keyword + " Successfully deleted!");
            }
        });
    };


    handleChangeKeyword = (event) => {
        if (event.target.name === "httpMethod" && event.target.value != "GET") {
            this.setState({showInterfaceField: true});
        }
        if (event.target.value == "GET") {
            this.setState({showInterfaceField: false});
        }

        if (this.state.modeKeyword) {
            this.setState({
                addNewKeywordForm: Object.assign({}, this.state.addNewKeywordForm,
                    {[event.target.name]: event.target.value})
            });
        } else {
            this.setState({
                editKeywordForm: Object.assign({}, this.state.editKeywordForm,
                    {[event.target.name]: event.target.value})
            });
        }
    };

    AddNewKeyword = () => {
        this.setState({modeKeyword: true});
        this.toggleKeywordModal();
    };

    showSweetAlert(message, icon) {
        return MySwal.fire({
            position: 'center',
            icon: icon,
            title: message,
            showConfirmButton: false,
            timer: 2000
        });
    };


    toggleModalShortcode = () => {
        this.setState({
            shortcodeModal: !this.state.shortcodeModal
        });
    };

    toggleKeywordModal = () => {
        this.setState({keywordModal: !this.state.keywordModal});
    };

    AddShortcode = () => {
        this.setState({modeShortcode: true});
        this.toggleModalShortcode();

    };

    handleSubmitShortcode = event => {
        event.preventDefault();
        this.setState({loader: true});

        if (this.state.modeShortcode) {
            const _data = {
                customerId: this.state.customerId,
                ...this.state.addMoShortcodeForm,
            };
            KeywordConfig.createMoShortCode(_data).then(res => {
                    this.setState({loader: false});
                    this.showSweetAlert("Shortcode successfully added", "success");
                    this.toggleModalShortcode();
                    this.getAllMoShortCodes(this.state.customerId);
                }
            ).catch(err => {
                this.showSweetAlert("Action Failed", "info");
            });
        } else {
            const _data = {
                customerId: this.state.customerId,
                ...this.state.editMoShortcode,
            };
            KeywordConfig.updateMoShortcode(_data).then(
                res => {
                    this.setState({loader: false});
                    this.showSweetAlert("Shortcode successfully added", "success");
                    this.toggleModalShortcode();
                    this.getAllMoShortCodes(this.state.customerId);
                }
            ).catch(err => {
                this.showSweetAlert("Action Failed", "info");
            });
        }
    };
    render() {
        const id = this.state.customer.id;
        let userIndex = 0;
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div className="mr-auto flex-row">
                        Customer Details : {this.state.customer.fullname}
                        <small>Showing all customer details.</small>
                    </div>
                    <div className="flex-row d-block d-md-flex">
                        {this.state.isApproved == 1 &&
                        this.state.paymentType == "Post-Paid" &&
                        <Button onClick={() => this.EditCustomer()} className="btn btn-pill mr-2 bg-success">Edit Customer</Button>
                        }
                        {this.state.isApproved == 1 &&
                        <Button onClick={() => this.RejectCustomer(this.state.customerId)} className="btn btn-pill mr-2 bg-danger">Disable Customer</Button>
                        }
                        {this.state.isApproved == 0 &&
                        <span>

                                <Button onClick={() => this.RejectCustomer(this.state.customerId)} className="btn btn-pill mr-2 bg-danger">Reject Customer</Button>
                                <Button onClick={() => this.toggleModal()} className="btn btn-pill mr-2 bg-success">Approve Customer</Button>
                            </span>
                        }
                        {
                            this.state.isApproved == 2 &&
                            <span>
                                <Button onClick={() => this.toggleModal()} className="btn btn-pill mr-2 bg-success">Approve Customer</Button>
                            </span>
                        }


                        {
                            this.state.activeTab === "5" &&
                            <button onClick={this.AddNewKeyword} className="btn btn-success ">Add Keyword</button>
                        }

                        {
                            this.state.activeTab === "4" &&
                            <Button onClick={() => this.AddShortcode()} className="btn mr-2 bg-primary">Add MO Shortcode</Button>
                        }

                        <Button onClick={this.ViewCustomerList} style={this.AddActionButtonStyle} className="btn-pill-right">View All Customers</Button>
                    </div>
                </div>
                <Container fluid>
                    <Modal isOpen={this.state.customerModal} toggle={this.toggleModalCustomer}>
                        <ModalHeader toggle={this.toggleModalCustomer}>Edit Customer</ModalHeader>
                        <form onSubmit={this.handleCustomerUpdateFomSubmission}>
                            <ModalBody>
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Tariff : </label>
                                    <select className="form-control" id="exampleFormControlSelect1" name="tariffFk" onChange={this.handleChangeDetails}
                                            value={this.state.editedCustomer.tariffFk}
                                    >
                                        <option value="0">Select a tariff</option>
                                        {this.state.tarrifsList.map(row => (
                                            <option key={row.id} value={row.id} >
                                                {row.tariffName}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <FormGroup>
                                    <label>Monthly sms limit :</label>
                                    <input className="form-control" name="monthlyLimit"
                                           value={this.state.editedCustomer.monthlyLimit}
                                           onChange={this.handleChangeDetails} type="number" required></input>
                                </FormGroup>

                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Auto Renew : </label>
                                    <select className="form-control" id="exampleFormControlSelect1" name="autoRenewal"
                                            onChange={this.handleChangeDetails}
                                            value={this.state.editedCustomer.autoRenewal}>
                                        <option value="">Select Status</option>
                                        <option value="1">ACTIVE</option>
                                        <option value="0">DISABLED</option>
                                    </select>
                                </div>

                            </ModalBody>
                            <ModalFooter>
                                <button className="btn btn-sm btn-success mr-3  px-5" type="submit">
                                    Save
                                </button>
                            </ModalFooter>
                        </form>
                    </Modal>

                    <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
                        <ModalHeader toggle={this.toggleModal}>Approve Customer</ModalHeader>
                        <form onSubmit={this.handleSubmit}>
                            <ModalBody>


                                {this.state.paymentType == "Post-Paid" &&
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Smsc Type : </label>
                                    <select className="form-control" id="exampleFormControlSelect1" name="smscType"
                                            onChange={this.handleSmsTypeChange}
                                            value={this.state.smscType}
                                    >
                                        <option value="-1">Select type</option>
                                        <option value="0">Shared SMSC</option>
                                        <option value="1">Dedicate SMSC</option>
                                    </select>
                                </div>

                                }


                                {this.state.showSmscDetails  &&
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Smsc Account : </label>
                                    <select className="form-control" id="exampleFormControlSelect1" name="smscId"
                                            onChange={this.handleChange}
                                            value={this.state.smscId}
                                    >
                                        <option value="-1">Select account</option>
                                        {this.state.smscList.map(row => (
                                            <option key={row.id} value={row.id} >
                                                {row.smscUsername}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                }
                                {this.state.paymentType == "Post-Paid" &&
                                <FormGroup>
                                    <label>Monthly sms limit :</label>
                                    <input className="form-control" name="monthlySmsLimit" onChange={this.handleChange} type="number" required></input>
                                </FormGroup>
                                }
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Tariff : </label>
                                    <select className="form-control" id="exampleFormControlSelect1" name="tariffId" onChange={this.handleChange}>
                                        <option value="0">Select a tariff</option>
                                        {this.state.tarrifsList.map(row => (
                                            <option key={row.id} value={row.id} >
                                                {row.tariffName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button className="btn btn-sm btn-success mr-3  px-5" type="submit">
                                    Approve
                                </button>
                                {/* <button onClick={this.hideToggelModal} className="btn btn-sm btn-danger  px-5">
                                    Cancel
                   </button> */}
                            </ModalFooter>
                        </form>
                    </Modal>

                    <div role="tabpanel card card-body">
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '1' })}
                                    onClick={() => { this.toggleTab('1'); }}>
                                    <span className="icon-people mr-2"></span>
                                    Customer Details
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '2' })}
                                    onClick={() => { this.toggleTab('2'); }}>
                                    <span className="icon-wallet mr-2"></span>
                                    Attachments
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '3' })}
                                    onClick={() => { this.toggleTab('3'); }}>
                                    <span className="fa fa-users mr-2"></span>
                                    User Accounts
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    className={classnames({active: this.state.activeTab === '4'})}
                                    onClick={() => {
                                        this.toggleTab('4');
                                    }}>
                                    {/*<span className="fa fa-address-book mr-2"></span>*/}
                                    MO Shortcodes
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    className={classnames({active: this.state.activeTab === '5'})}
                                    onClick={() => {
                                        this.toggleTab('5');
                                    }}>
                                    {/*<span className="fa fa-keycdn mr-2"></span>*/}
                                    Keywords
                                </NavLink>
                            </NavItem>

                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">

                                <Col xl="12">
                                    <div>
                                        <div className="card">
                                            <div className="card-header px-0">
                                                <h4 className="text-center mt-2">Customer Details</h4>
                                            </div>
                                            <hr className="my-0" />
                                            <div className="card-body mt-2 py-1">
                                                <div className="px-md-3 px-2">
                                                    <div className="px-2">
                                                        <p className="mb-3 text-dark"><strong>Customer name:</strong> &nbsp; <span name="name">{this.state.customer.fullname}</span></p>
                                                        <p className="mb-3 text-dark"><strong>Email:</strong> &nbsp; <span name="email">{this.state.customer.email}</span></p>
                                                        <p className="mb-3 text-dark"><strong>Phone:</strong> &nbsp; <span name="phone">{this.state.customer.phonenumber}</span></p>
                                                        <p className="mb-3 text-dark"><strong>Address:</strong> &nbsp; <span name="address">{this.state.customer.location}</span></p>
                                                        <p className="mb-3 text-dark"><strong>Status:</strong> &nbsp;

                                                            <span name="status"></span>{this.state.customer.isActive == 1 ? "Active" : "Pending"}
                                                        </p>
                                                        <p className="mb-3 text-dark"><strong>Customer Type:</strong> &nbsp;
                                                            <span name="status"></span>{this.state.customer.customerType}
                                                        </p>
                                                        <p className="mb-3 text-dark"><strong>Payment Type:</strong> &nbsp;
                                                            <span name="status"></span>{this.state.customer.paymentType}
                                                        </p>
                                                        <p className="mb-3 text-dark"><strong>Date registered:</strong> &nbsp; <span name="regdate">{this.formatDate(this.state.customer.createdAt)}</span></p>
                                                        <p className="mb-3 text-dark"><strong>ID number:</strong> &nbsp; <span name="idnumber">{this.state.customer.idNumber}</span></p>
                                                        {/* <p className="mb-3 text-dark"><strong>Monthly Sms Limit:</strong> &nbsp; <span name="smsLimit">19900302-600123-456791</span></p> */}
                                                        {/* <p className="mb-3 text-dark"><strong>Attachment:</strong> &nbsp; <span name="attachment"><a href="#">View Attachment</a></span></p> */}
                                                        {/* <p className="mb-3 text-dark"><strong>SMSC ID:</strong> &nbsp; <span name="smsc">ID-01XXXX</span></p> */}
                                                        {/* <p className="mb-3 text-dark"><strong>Tariff:</strong> &nbsp; <span name="tariff">Dabo Bando</span></p> */}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Col>


                            </TabPane>
                            <TabPane tabId="2">

                                <Col xl="12">
                                    <div>
                                        <div className="card">
                                            <div className="card-header px-0">
                                                <h4 className="text-center mt-2">Customer Attachment</h4>
                                            </div>
                                            <hr className="my-0" />
                                            <div className="card-body mt-2 py-1">
                                                <div className="px-md-3 px-2">
                                                    <div className="px-2 text-center">
                                                        {/* <Button onClick={this.ViewPdf}>View Attachment</Button> */}
                                                        {/* <img className="img-fluid" src={this.state.customer.attachment} alt="Attachment" /> */}
                                                        {/* <Document
                                                            file={`data:application/pdf;base64,${this.state.customer.imageBlob}`}
                                                        >


                                                        <Page pageNumber={1} />
                                                        </Document> */}
                                                        <button onClick={this.previewAttachment} className="btn btn-sm btn-danger  px-5 text-center">
                                                            Preview Attachment
                                                        </button>


                                                        {/* <iframe src={this.state.attachmentUrl} width="100%" height="500px">
    </iframe>  */}
                                                        {/* <iframe src="/img/customer-attachment/222" width="100%" height="500px" frameborder="0">
    </iframe> */}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Col>



                            </TabPane>


                            <TabPane tabId="3">


                                <Col xl="12">
                                    <div>
                                        <div className="card">
                                            <div className="card-header px-0">
                                                <h4 className="text-center mt-2">User Accounts</h4>
                                            </div>
                                            <hr className="my-0" />
                                            <div className="card-body mt-2 py-1">
                                                <div className="px-md-3 px-2">
                                                    <div className="px-2">
                                                        <table className="table table-striped my-4 w-100">
                                                            <thead>
                                                            <tr>
                                                                <th data-priority="1">#</th>
                                                                <th>FULL NAME</th>
                                                                <th>USERNAME</th>
                                                                <th className="sort-numeric">USER MONTHLY SMS LIMIT</th>

                                                                <th>STATUS</th>
                                                                <th>LAST LOGIN</th>

                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {this.state.usersList.map(row =>
                                                                <tr className="gradeA" key={row.id}>
                                                                    <td>{userIndex += 1}</td>
                                                                    <td>{row.name}</td>
                                                                    <td>{row.username}</td>
                                                                    <td>{row.userMonthlySmsLimit}</td>
                                                                    <td>

                                                                        {row.isActive == 1 &&
                                                                        <span className="badge badge-success">Active</span>
                                                                        }
                                                                        {row.isActive == 0 &&
                                                                        <span className="badge badge-success">Pending</span>
                                                                        }
                                                                        {row.isActive == 2 &&
                                                                        <span className="badge badge-danger">Disabled</span>
                                                                        }
                                                                    </td>
                                                                    <td>{this.formatDate(row.registrationDate)}</td>

                                                                </tr>
                                                            )}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Col>


                            </TabPane>





















                            <TabPane tabId="4">
                                <Col xl="12">
                                    <div>
                                        <div className="card">
                                            <div className="card-header px-0">
                                                <h4 className="text-center mt-2">SHORTCODES</h4>
                                            </div>
                                            <hr className="my-0"/>
                                            <div className="card-body mt-2 py-1">
                                                <div className="px-md-3 px-2">
                                                    <div className="px-2 text-center">

                                                        <table className="table">
                                                            <caption>List of MO Shortcodes</caption>
                                                            <thead>
                                                            <tr>
                                                                <th scope="col">#</th>
                                                                <th scope="col">SHORTCODE</th>
                                                                <th scope="col">TYPE</th>
                                                                <th scope="col">URL</th>
                                                                <th scope="col">HTTP METHOD</th>
                                                                <th scope="col">PARAMS</th>
                                                                <th scope="col">STATUS</th>
                                                                <th scope="col">ACTIONS</th>
                                                            </tr>

                                                            </thead>
                                                            <tbody>

                                                            {
                                                                this.state.shortcodeList.map((row, index) => {
                                                                    return (
                                                                        <tr>
                                                                            <th scope="row">{index + 1}</th>
                                                                            <td>{row.code}</td>
                                                                            <td>{row.type}</td>
                                                                            <td>{row.url}</td>
                                                                            <td>{row.httpMethod}</td>
                                                                            <td>{row.params}</td>
                                                                            <td>{row.status}</td>
                                                                            <td>
                                                                                    <span
                                                                                        className="btn badge-success mr-1"
                                                                                        onClick={() => {
                                                                                            this.EditShortcodeMode(row);
                                                                                        }}>Edit</span>

                                                                                <span className="btn badge-danger ml-1"
                                                                                      onClick={() => {
                                                                                          this.AlertDeleteShortcode(row);
                                                                                      }}>Delete</span>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </TabPane>

                            <TabPane tabId="5">
                                <Col xl="12">
                                    <div>
                                        <div className="card">
                                            <div className="card-header px-0">
                                                <h4 className="text-center mt-2">KEYWORDS</h4>
                                            </div>
                                            <hr className="my-0"/>
                                            <div className="card-body mt-2 py-1">
                                                <div className="px-md-3 px-2">
                                                    <div className="px-2">
                                                        <table className="table">
                                                            <caption>List of Keyword</caption>
                                                            <thead>
                                                            <tr>
                                                                <th scope="col">#</th>
                                                                <th scope="col">KEYWORD</th>
                                                                <th scope="col">TYPE</th>
                                                                <th scope="col">URL</th>
                                                                <th scope="col">HTTP METHOD</th>
                                                                <th scope="col">PARAMS</th>
                                                                <th scope="col">STATUS</th>
                                                                <th scope="col">ACTIONS</th>
                                                            </tr>

                                                            </thead>
                                                            <tbody>

                                                            {
                                                                this.state.keywordList.map((row, index) => {
                                                                    return (
                                                                        <tr>
                                                                            <th scope="row">{index + 1}</th>
                                                                            <td>{row.keyword}</td>
                                                                            <td>{row.type}</td>
                                                                            <td>{row.url}</td>
                                                                            <td>{row.httpMethod}</td>
                                                                            <td>{row.params}</td>
                                                                            <td>{row.status}</td>
                                                                            <td>
                                                                                    <span
                                                                                        className="btn badge-success mr-1"
                                                                                        onClick={() => {
                                                                                            this.EditKeyword(row);
                                                                                        }}>Edit</span>

                                                                                <span className="btn badge-danger ml-1"
                                                                                      onClick={() => {
                                                                                          this.AlertDeleteKeyword(row);
                                                                                      }}>Delete</span>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </TabPane>








                        </TabContent>
                    </div>












                    <Modal isOpen={this.state.shortcodeModal} toggle={this.toggleModalShortcode}>
                        <ModalHeader
                            toggle={this.toggleModalShortcode}>{this.state.modeShortcode ? "Add New Shortcode" : "Edit Shortcode"}</ModalHeader>
                        <form onSubmit={this.handleSubmitShortcode}>
                            {/* <MoonLoader css={this.override} size={60} loading={this.state.loader} /> */}
                            <ModalBody>
                                <div className="row">
                                    <div className="form-group col-md-6 px-md-2 px-1">
                                        <label>Code :</label>
                                        <input className="form-control" name="code"
                                               onChange={this.handleChangeShortcode}
                                               value={this.state.modeShortcode ? this.state.addMoShortcodeForm.code : this.state.editMoShortcode.code}
                                               required></input>
                                    </div>

                                    <div className="form-group col-md-6 px-md-2 px-1">
                                        <label>Type :</label>
                                        <select className="form-control" name="type"
                                                onChange={this.handleChangeShortcode}
                                                value={this.state.modeShortcode ? this.state.addMoShortcodeForm.type : this.state.editMoShortcode.type}
                                                required>

                                            <option>--Select type--</option>
                                            <option value="DEDICATED">Dedicated</option>
                                            <option value="SHARED">Shared</option>
                                        </select>
                                    </div>

                                    <div className="form-group col-md-6 px-md-2 px-1">
                                        <label>HTTP Method :</label>
                                        <select className="form-control" name="httpMethod"
                                                onChange={this.handleChangeShortcode}
                                                value={this.state.modeShortcode ? this.state.addMoShortcodeForm.httpMethod : this.state.editMoShortcode.httpMethod}
                                                required>
                                            <option>Select http method</option>
                                            <option value="GET">GET</option>
                                            <option value="POST">POST</option>
                                            <option value="SMPP">SMPP</option>
                                        </select>
                                    </div>
                                    {
                                        this.state.showInterfaceField &&
                                        <div className="form-group col-md-6 px-md-2 px-1">
                                            <label>Sending Interface :</label>
                                            <select className="form-control" name="sendingInterface"
                                                    onChange={this.handleChangeShortcode}
                                                    value={this.state.modeShortcode ? this.state.addMoShortcodeForm.sendingInterface : this.state.editMoShortcode.sendingInterface}
                                                    required>
                                                <option>Select sending interface</option>
                                                <option value="JSON">JSON</option>
                                                <option value="XML">XML</option>
                                            </select>
                                        </div>

                                    }

                                    <div className="form-group col-md-6 px-md-2 px-1">
                                        <label>Url :</label>
                                        <input className="form-control" name="url" onChange={this.handleChangeShortcode}
                                               value={this.state.modeShortcode ? this.state.addMoShortcodeForm.url : this.state.editMoShortcode.url}
                                               required></input>
                                    </div>

                                    <div className="form-group col-md-6 px-md-2 px-1">
                                        <label>Client Name :</label>
                                        <input className="form-control" name="clientName"
                                               onChange={this.handleChangeShortcode}
                                               value={this.state.modeShortcode ? this.state.addMoShortcodeForm.clientName : this.state.editMoShortcode.clientName}
                                               required></input>
                                    </div>

                                    <div className="form-group col-md-6 px-md-2 px-1">
                                        <label>Params :</label>
                                        <input className="form-control" name="params"
                                               onChange={this.handleChangeShortcode}
                                               value={this.state.modeShortcode ? this.state.addMoShortcodeForm.params : this.state.editMoShortcode.params}
                                               required></input>
                                    </div>

                                    <div className="form-group col-md-6 px-md-2 px-1">
                                        <label>Inactive Response :</label>
                                        <textarea className="form-control" name="inactiveResponse"
                                                  onChange={this.handleChangeShortcode}
                                                  value={this.state.modeShortcode ? this.state.addMoShortcodeForm.inactiveResponse : this.state.editMoShortcode.inactiveResponse}
                                                  required></textarea>
                                    </div>

                                    <div className="form-group col-md-6 px-md-2 px-1">
                                        <label>Status :</label>
                                        <select className="form-control" name="status"
                                                onChange={this.handleChangeShortcode}
                                                value={this.state.modeShortcode ? this.state.addMoShortcodeForm.status : this.state.editMoShortcode.status}
                                        >
                                            <option>Select sending interface</option>
                                            <option value="ACTIVE">ACTIVE</option>
                                            <option value="INACTIVE">INACTIVE</option>
                                        </select>
                                    </div>

                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button className="btn btn-sm  mr-3 px-4" style={this.AddActionButtonStyle}>
                                    Save
                                </button>
                            </ModalFooter>
                        </form>
                    </Modal>

                    <Modal isOpen={this.state.keywordModal} toggle={this.toggleKeywordModal}>
                        <ModalHeader
                            toggle={this.toggleKeywordModal}>{this.state.modeKeyword ? "Add New Keyword" : "Edit Keyword " + this.state.editKeywordForm.keyword}</ModalHeader>
                        <form onSubmit={this.handleSubmitKeyword}>

                            {/* <MoonLoader size={60} css={loaderCss} loading={this.state.loader} /> */}
                            <ModalBody>

                                <div className="row">

                                    <div className="form-group col-md-6 px-md-2 px-1">
                                        <label>MO Shortcode :</label>
                                        <select className="form-control" name="shortcode"
                                                onChange={this.handleChangeKeyword}
                                                required>

                                            <option>--Select code--</option>
                                            {
                                                this.state.sharedShortcodeList.map(row => {
                                                    if(!this.state.modeKeyword && this.state.editKeywordForm.shortCode === row.code) {
                                                        return (
                                                            <option selected value={row.code}>{row.code}</option>
                                                        );

                                                    }else{
                                                        return (
                                                            <option value={row.code}>{row.code}</option>
                                                        );
                                                    }
                                                })
                                            }
                                        </select>
                                    </div>

                                    <div className="form-group col-md-6 px-md-2 px-1">
                                        <label>Keyword Name :</label>
                                        <input className="form-control" name="keyword"
                                               onChange={this.handleChangeKeyword}
                                               value={this.state.modeKeyword ? this.state.addNewKeywordForm.keyword : this.state.editKeywordForm.keyword}
                                               required/>
                                    </div>

                                    <div className="form-group col-md-6 px-md-2 px-1">
                                        <label>HTTP Method :</label>
                                        <select className="form-control" name="httpMethod"
                                                onChange={this.handleChangeKeyword}
                                                required>
                                            <option>Select http method</option>
                                            {['GET', 'POST', 'SMPP'].map((m, i)=>{
                                                if(!this.state.modeKeyword && this.state.editKeywordForm.httpMethod === m) {
                                                    return <option selected key={i} value={`${m}`}>{m}</option>
                                                }else{
                                                    return <option key={i} value={`${m}`}>{m}</option>
                                                }
                                            })}
                                        </select>
                                    </div>

                                    <div className="form-group col-md-6 px-md-2 px-1">
                                        <label>Url :</label>
                                        <input className="form-control" name="url" onChange={this.handleChangeKeyword}
                                               value={this.state.modeKeyword ? this.state.addNewKeywordForm.url : this.state.editKeywordForm.url}
                                               required></input>
                                    </div>

                                    {
                                        this.state.showInterfaceField &&
                                        <div className="form-group col-md-6 px-md-2 px-1">
                                            <label>Sending Interface :</label>
                                            <select className="form-control" name="sendingInterface"
                                                    onChange={this.handleChangeKeyword}
                                                    required>
                                                <option>Select sending interface</option>
                                                {['JSON', 'XML'].map((m, i)=>{
                                                    if(!this.state.modeKeyword && this.state.editKeywordForm.sendingInterface === m) {
                                                        return <option selected key={i} value={`${m}`}>{m}</option>
                                                    }else{
                                                        return <option key={i} value={`${m}`}>{m}</option>
                                                    }
                                                })}
                                            </select>
                                        </div>}

                                    <div className="form-group col-md-6 px-md-2 px-1">
                                        <label>Client Name :</label>
                                        <input className="form-control" name="clientName"
                                               onChange={this.handleChangeKeyword}
                                               value={this.state.modeKeyword ? this.state.addNewKeywordForm.clientName : this.state.editKeywordForm.clientName}
                                               required></input>
                                    </div>

                                    <div className="form-group col-md-6 px-md-2 px-1">
                                        <label>Params :</label>
                                        <input className="form-control" name="params"
                                               onChange={this.handleChangeKeyword}
                                               value={this.state.modeKeyword ? this.state.addNewKeywordForm.params : this.state.editKeywordForm.params}
                                               required></input>
                                    </div>

                                    <div className="form-group col-md-6 px-md-2 px-1">
                                        <label>Inactive Response :</label>
                                        <textarea className="form-control" name="inactiveResponse"
                                                  onChange={this.handleChangeKeyword}
                                                  value={this.state.modeKeyword ? this.state.addNewKeywordForm.inactiveResponse : this.state.editKeywordForm.inactiveResponse}
                                                  required></textarea>
                                    </div>

                                    <div className="form-group col-md-6 px-md-2 px-1">
                                        <label>Status :</label>
                                        <select className="form-control" name="status"
                                                onChange={this.handleChangeKeyword}
                                                required>
                                            <option value="">Status</option>
                                            {['ACTIVE', 'INACTIVE'].map((m, i)=>{
                                                if(!this.state.modeKeyword && this.state.editKeywordForm.status === m) {
                                                    return <option selected key={i} value={`${m}`}>{m}</option>
                                                }else{
                                                    return <option key={i} value={`${m}`}>{m}</option>
                                                }
                                            })}
                                        </select>
                                    </div>

                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button className="btn btn-sm  mr-3 px-4" style={this.AddActionButtonStyle}>
                                    Save
                                </button>
                            </ModalFooter>
                        </form>

                    </Modal>

                </Container>
            </ContentWrapper>
        );
    }
}

export default CustomerDetails;
