import axios from "./axios";

class KeywordConfig {

    // MO Shortcode API calls

    getAllMoShortcodes = () => {
        return axios.get("/mo_shortcode");
    };

    createMoShortCode = (_data) => {
        return axios.post("/mo_shortcode/add", _data);
    };

    updateMoShortcode = (_data) => {
        return axios.put("/mo_shortcode/update", _data);
    };

    deleteMoShortcode = (_id) => {
        return axios.delete("/mo_shortcode/delete/" + _id);
    };

    getMoShortcodeById = (_id) => {
        return axios.get("/mo_shortcode/id/"+_id)
    }

    getMoShortcodeByCustomer = (customerId) =>{
        return axios.get("/mo_shortcode/customer/"+customerId)
    }


    getSharedMoShortcodes = () => {
        return axios.get("/mo_shortcode/shared")
    }

    // Keyword config API calls

    getAllKeywords = () => {
        return axios.get("/keyword");
    };

    createKeyword = (_data) => {
        return axios.post("/keyword/add", _data)
    };

    updateKeyword = (_data) => {
        return axios.put("/keyword/update", _data)
    }

    deleteKeyword = (_id) => {
        return axios.delete("/keyword/delete/"+_id)
    }

    getKeywordById = (_id) => {
        return axios.get("/keyword/"+_id)
    }

    getKeywordByCustomerId = (customerId) => {
        return axios.get("/keyword/customer/"+customerId)
    }

}


export default new KeywordConfig;