import React, { Component } from "react";
import ContentWrapper from "../../../Layout/ContentWrapper";
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import $ from "jquery";
import Moment from 'moment';
import { SenderIdService } from "../../../../services";

import ReactDatatable from '@ashvin27/react-datatable';
import { AuthService } from '../../../../services';
import { Redirect } from 'react-router-dom';
import KeywordConfig from "../../../../services/KeywordConfigService";
import { Fragment } from "react";
import KeywordConfigService from "../../../../services/KeywordConfigService";
import { MoonLoader } from "react-spinners";
import { SuccessAlert, DeleteAlert } from "../../../Common/AppAlerts";
import withReactContent from 'sweetalert2-react-content';
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);

class MoShortcode extends Component {
  state = {
    moShortcodeList: [],
    mode: true,
    addMoShortcodeForm: {
      code: "",
      type: "SHARED",
      inactiveResponse: "",
      status: "ACTIVE"
    },

    editMoShortcode: {
      id: 0,
      code: "",
      type: "SHARED",
      inactiveResponse: "",
      status: "ACTIVE"
    },
    modal: false,
    loading: true,
    loader: false
  };


  componentDidMount() {
    const isAuthenticated = AuthService.isAuthenticated();
    if (!isAuthenticated) {
      this.setState({ redirect: "/login" });
    }
    this.getAllMoShortCodes();

  }

  getAllMoShortCodes = () => {
    KeywordConfig.getAllMoShortcodes().then(res => {
      const response = res.data;
      this.setState({ loading: false });
      this.setState({ moShortcodeList: response });
    });
  };

  getSingleMoShortCode = (id) => {
    this.setState({ loader: true });
    KeywordConfigService.getMoShortcodeById(id).then(res => {
      const response = res.data;
      console.log(response);
      this.setState({ editMoShortcode: response });
      console.log(this.state.editMoShortcode);
      this.setState({ loader: false });
    });
  };


  EditShortcodeMode = (row) => {
    this.setState({ mode: false });
    this.setState({
      editMoShortcode: Object.assign(this.state.editMoShortcode, { id: row.id })
    });
    console.log(this.state.editMoShortcode.id);
    this.getSingleMoShortCode(row.id);
    console.log(this.state.mode);
    this.toggleModal();

  };


  columns = [
    {
      key: "id",
      text: "ID",
      cell: (record, index) => {
        return index + 1;
      }
    },
    {
      key: "code",
      text: "SHORTCODE",
    },

    {
      key: "type",
      text: "TYPE",

    },
    {
      key: "status",
      text: "STATUS",

    },
    {
      key: "id",
      text: "ACTION",
      cell: (record, index) => {
        // if (record.is_approved == 1) {
        return (
          <Fragment>
            <span className="btn badge-success mr-1" onClick={() => {
              this.EditShortcodeMode(record);
            }}>Edit</span>

            <span className="btn badge-danger ml-1" onClick={() => {
              this.AlertDeleteShortcode(record);
            }}>Delete</span></Fragment>
        );
        // }
      }
    },
  ];



  AddMoShortCode = () => {
    this.setState({ mode: true });
    this.toggleModal();
  };


  handleSubmit = event => {
    event.preventDefault();

    console.log(this.state.mode);

    this.setState({ loader: true });

    if (this.state.mode) {
      const _data = {
        code: this.state.addMoShortcodeForm.code,
        inactiveResponse: this.state.addMoShortcodeForm.inactiveResponse,
        type: "SHARED",
        customerId: -1
      };
      KeywordConfig.createMoShortCode(_data).then(
        res => {
          this.setState({ loader: false });
          this.showSweetAlert("MO Shortcode added successfully", "success");
          this.toggleModal();
          this.getAllMoShortCodes();
        }
      ).catch(err => {
        this.setState({ loader: false });
        this.toggleModal();
        this.showSweetAlert("Action Failed", "error");
      });
    } else {
      const _data = {
        ...this.state.editMoShortcode,
      };
      KeywordConfig.updateMoShortcode(_data).then(
        res => {
          this.setState({ loader: false });
          this.showSweetAlert("Action completed successfully", "success");
          this.toggleModal();
          this.getAllMoShortCodes();
        }
      ).catch(err => {
        this.setState({ loader: false });
        this.toggleModal();
        this.showSweetAlert("Action Failed", "error");
      });
    }
  };

  showSweetAlert(message, type) {
    return MySwal.fire({
      position: 'center',
      icon: type,
      title: message,
      showConfirmButton: false,
      timer: 2000
    });
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  handleChange = event => {
    if (this.state.mode) {
      this.setState({
        addMoShortcodeForm: Object.assign({}, this.state.addMoShortcodeForm,
          { [event.target.name]: event.target.value })
      });
    } else {
      this.setState({
        editMoShortcode: Object.assign({}, this.state.editMoShortcode,
          { [event.target.name]: event.target.value })
      });
    }
  };

  formatDate = (date) => {
    return Moment(date).format('lll');
  };
  AddActionButtonStyle = {
    color: 'white',
    background: "#003366"
  };

  DeleteMoShortcode = (row) => {
    KeywordConfigService.deleteMoShortcode(row.id).then(res => {
      const response = res.data;
      this.getAllMoShortCodes();
    });
  };

  AlertDeleteShortcode = (row) => {
    DeleteAlert().then((willDelete) => {
      if (willDelete) {
        this.DeleteMoShortcode(row);
        SuccessAlert("Successfully deleted!");
      }
    });
  };

  config = {
    page_size: 10,
    length_menu: [10, 25, 50],
    show_filter: true,
    show_pagination: true,
    pagination: 'advance',
    filename: "Contact List",
    button: {

    },
    language: {
      loading_text: "Please be patient while data loads..."
    }
  };


  override = `
  position: absolute;
  top: 46%;
  left:43%;
  display: block;
  margin: 0 auto;
  border-color: red;
  z-index: 99;
`;

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div className="mr-auto flex-row">
            <h3 style={{ fontWeight: 500 }}>Manage MO Shortcodes</h3>
            <small>Showing all MO shortcodes.</small>
          </div>
          <div className="flex-row">
            <Button onClick={this.AddMoShortCode} style={this.AddActionButtonStyle} className="btn-pill-right">
              <i className="fa fa-plus mr-2"></i>
              Add MO Shortcode</Button>

            <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
              <ModalHeader toggle={this.toggleModal}>{this.state.mode ? "Add New Shortcode" : "Edit Shortcode"}</ModalHeader>
              <form className="px-2" onSubmit={this.handleSubmit}>

                <MoonLoader css={this.override} size={60} loading={this.state.loader} />

                <ModalBody>

                  <div className="row">
                    <div className="form-group col-md-12 px-md-2 px-1">
                      <label>Code :</label>
                      <input className="form-control" name="code" onChange={this.handleChange}
                        value={this.state.mode ? this.state.addMoShortcodeForm.code : this.state.editMoShortcode.code}
                        required ></input>
                    </div>

                    <div className="form-group col-md-12 px-md-2 px-1">
                      <label>Type :</label>
                      <select className="form-control" name="type" disabled onChange={this.handleChange}
                        value={this.state.mode ? this.state.addMoShortcodeForm.type : this.state.editMoShortcode.type} required>

                        <option>--Select type--</option>
                        <option value="DEDICATED">Dedicated</option>
                        <option selected value="SHARED">Shared</option>
                      </select>
                    </div>
                    <div className="form-group col-md-12 px-md-2 px-1">
                      <label>Inactive Response :</label>
                      <textarea className="form-control" name="inactiveResponse" onChange={this.handleChange}
                        value={this.state.mode ? this.state.addMoShortcodeForm.inactiveResponse : this.state.editMoShortcode.inactiveResponse}
                        required ></textarea>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <button className="btn btn-sm  mr-3 px-4" style={this.AddActionButtonStyle}>
                    Save
                  </button>
                </ModalFooter>
              </form>
            </Modal>
          </div>
        </div>
        <Container fluid>
          <Card>
            <CardHeader>
              <div className="row">
                <Card className="col-sm-12">
                  <CardBody>
                    {/* <form onSubmit={this.onSubmit}>
                      <div className="form-row align-items-center">
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label>From :</label>
                            <input className="form-control" type="date" />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label>To: </label>
                            <input className="form-control" type="date" />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label htmlFor="exampleFormControlSelect1">No of records: </label>
                            <select className="form-control" id="exampleFormControlSelect1">
                              <option>All</option>
                              <option>100</option>
                              <option>200</option>
                              <option>500</option>
                              <option>1000</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <button type="submit" className="btn btn-info mt-2">
                            Search
                          </button>
                        </div>

                      </div>
                    </form> */}
                  </CardBody>
                </Card>
              </div>
            </CardHeader>
            <CardBody>

              <ReactDatatable
                config={this.config}
                records={this.state.moShortcodeList}
                columns={this.columns}
                loading={this.state.loading}
              />
            </CardBody>
          </Card>
        </Container>
      </ContentWrapper>
    );
  }
}

export default MoShortcode;
